<template>
  <div class="mx-auto flex h-full w-full max-w-sm flex-col justify-center self-center px-4">
    <h1 class="mb-8 text-center text-2xl font-black">
      {{ $t("auth.title--login") }}
    </h1>

    <form
      @submit.prevent="loginAction"
      @keydown="form.onKeydown($event)"
      name="login"
      class="flex flex-col space-y-2"
      aria-autocomplete="off"
      autocomplete="off"
    >
      <t-input-group :label="$t('auth.form.email')">
        <t-input
          v-model="form.email"
          required
          type="email"
          name="email"
          :variant="{ danger: form.errors.has('email') }"
          :disabled="form.busy || !csrfReady"
        />
      </t-input-group>

      <t-input-group :label="$t('auth.form.password')">
        <t-input
          v-model="form.password"
          required
          name="password"
          type="password"
          :variant="{ danger: form.errors.has('password') }"
          :disabled="form.busy || !csrfReady"
        />
      </t-input-group>

      <t-button type="submit" :disabled="form.busy || !csrfReady">{{ form.busy ? $t("auth.logging-in") : $t("auth.btn--login") }}</t-button>

      <p class="px-4 text-center text-xs" v-show="!csrfReady">Connection with server failed, please restart the app to retry.</p>
    </form>

    <div class="mt-8 space-y-4 text-center font-medium">
      <p>
        <router-link is="t-button" variant="link" :to="{ name: 'forgot-password' }">{{ $t("auth.btn--forgot-password") }}</router-link>
      </p>

      <p class="maw-w-xs text-sm">
        {{ $t("auth.callout--register") }}
        <router-link is="t-button" variant="link" :to="{ name: 'register' }">{{ $t("auth.btn--create-account") }}</router-link>
      </p>
    </div>
  </div>
</template>

<script>
import { Form } from "vform";
import { AUTH_REQUEST } from "actions/auth";
import { mapGetters } from "vuex";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      form: new Form({
        email: "",
        password: "",
      }),
      form_loading: false,
      form_error: null,
    };
  },
  methods: {
    async loginAction() {
      const { email, password } = this.form;
      const device_name = this.$browserDetect.meta.ua; // to be checked

      this.form_loading = true;
      this.form_error = null;

      if (this.form.errors.any()) {
        this.form_loading = false;
        return;
      }

      this.$store
        .dispatch(AUTH_REQUEST, { email, password, device_name })
        .then(() => {
          this.$router.push({ name: "home" });
        })
        .catch((err) => {
          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 422:
                this.form.errors.set(err.response.data.errors); // assign field errors
                this.form_error = err.response.data.message;
                return;
            }
          }
          this.form.errors.set([]);
        })
        .then(() => {
          this.form_loading = false;
        });
    },
  },
  computed: {
    ...mapGetters([
      // "isAuthenticated",
      // "isProfileLoaded",
      // "getUsername",
      "getLanguage",
      // "getAvatar",
      // "isCheckedIn",
      // "currentEvent",
      "csrfReady",
    ]),
  },
};
</script>
